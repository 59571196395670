import React from "react";

class MeasureInput extends React.Component {

    render() {

        return (
            <div className="col-md-3 col-6 mb-4">
                <div className="row align-items-center">
                    <div className="col-2">
                        {this.props.number}:
                    </div>
                    <div className="col-10">
                        <input type="number" className="form-control" name={'measurement[' + this.props.number +'][input]'} placeholder="Wert in mm" />
                    </div>
                </div>
                {!this.props.noBevel &&
                    <div className="row align-items-center mt-2">
                        <div className="col-10 ms-auto">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="Stiege/Schräge" name={'measurement[' + this.props.number +'][check]'} id={this.props.id} />
                                    <label className="form-check-label" htmlFor={this.props.id}>
                                        Stiege/Schräge
                                    </label>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default MeasureInput;