import React from "react";
import SubSelection from "./SubSelection";
import Title from "./Title";

class MainSelection extends React.Component {

    checkDependencies() {
        let dependencies = this.props.selection.properties.dependencies;
        // console.log(typeof dependencies !== 'undefined' ? dependencies : 'foo');
        let dependenciesData = false;
        if(typeof dependencies !== 'undefined') {
            dependenciesData = dependencies.join(',');
        }
        return dependenciesData;
    };

    constructor(props) {
        super(props);
        this.state = {
            name: "React"
        };
        this.onChangeValue = this.onChangeValue.bind(this);
    }

    getMainSelection() {
        let mainSelections = [];
        document.querySelectorAll('.main-selection').forEach(mainSelection => {
            mainSelections.push(mainSelection);
        });
        return mainSelections;
    }

    getSubSelection() {
        let subSelections = [];
        document.querySelectorAll('.sub-selection').forEach(subSelection => {
            subSelections.push(subSelection);
        });
        return subSelections;
    }

    getSelectedRadios() {
        let selectedRadios = [];
        document.querySelectorAll('input[type="radio"]:checked').forEach(selectedRadio => {
            if(selectedRadio.getAttribute('name') !== 'option[sides][]') {
                selectedRadios.push(selectedRadio);
            }
        });
        return selectedRadios;
    }

    handleDependencies() {
        let mainSelections = this.getMainSelection();
        for(let i in mainSelections) {
            mainSelections[i].style.display = 'block';
            if(mainSelections[i].getAttribute('data-dependencies') !== '' && typeof mainSelections[i].getAttribute('data-dependencies') !== 'undefined' && mainSelections[i].getAttribute('data-dependencies') !== 'false') {
                mainSelections[i].style.display = 'none';
                let dataDependencies = mainSelections[i].getAttribute('data-dependencies');
                for (let j in window.summary) {
                    let identifier = window.summary[j].identifier;
                    // console.log('summary identifier: ' + identifier);
                    if(dataDependencies.includes(identifier)) {
                        mainSelections[i].style.display = 'block';
                    }
                }
            }
        }

        let subSelections = this.getSubSelection();
        for(let i in subSelections) {
            subSelections[i].style.display = 'block';
            if(subSelections[i].getAttribute('data-dependencies') !== '' && typeof subSelections[i].getAttribute('data-dependencies') !== 'undefined' && subSelections[i].getAttribute('data-dependencies') !== 'false') {
                subSelections[i].style.display = 'none';
                let dataDependencies = subSelections[i].getAttribute('data-dependencies');
                for (let j in window.summary) {
                    let identifier = window.summary[j].identifier;
                    // console.log('summary identifier: ' + identifier);
                    if(dataDependencies.includes(identifier)) {
                        subSelections[i].style.display = 'block';
                    }
                }
            }
        }
    };

    updateSummary() {
        let selectedRadios = this.getSelectedRadios();
        document.querySelectorAll('.form-check-button').forEach(formCheckLabel => {
            formCheckLabel.classList.remove('active');
			const customInput = formCheckLabel.querySelector('.customInputWrapper');
			if(customInput) {
				// customInput.setAttribute('readonly', true);
				const customInputField = customInput.querySelector('.form-control');
				if(customInputField.value === '') {
					customInput.classList.add('d-none');
					customInput.value = '';
				}
			}
        });
        window.summary = [];
        for (let i in selectedRadios) {
            // console.log('selected: ' + selectedRadios[i].getAttribute('data-identifier'));
            let parent = selectedRadios[i].getAttribute('data-parent');
            let identifier = selectedRadios[i].getAttribute('data-identifier');
            let price = selectedRadios[i].getAttribute('data-price');
            window.summary[parent] = {'identifier': identifier, 'price': price};
            if(document.getElementById('label_' + identifier)) {
                document.getElementById('label_' + identifier).classList.add('active');
				const customInput = document.getElementById('label_' + identifier).querySelector('.customInputWrapper');
				if(customInput) {
					// customInput.removeAttribute('readonly');
					customInput.classList.remove('d-none');
					// customInput.focus();
				}
            }
        }
        window.buildSummary();
    }

    ifIsActiveNextNode(node) {
        let nextNode = node.target.parentNode.parentNode.parentNode.parentNode;
        if(nextNode.hasAttribute('class')) {
            if(nextNode.classList.contains('main-selection')) {
                for (let i = 0; i < 999; i++) {
                    if(nextNode.nextSibling) {
                        nextNode = nextNode.nextSibling;
                        if(nextNode) {
                            if(nextNode.hasAttribute('class')) {
                                if(nextNode.classList.contains('main-selection')) {
                                    if(nextNode.style.display === 'block') {
                                        nextNode.style.opacity = 1;
                                        nextNode.style.pointerEvents = 'all';
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    onChangeValue(event) {
        // console.log(event.target.value);
        // console.log('identifier: ' + event.target.getAttribute('data-identifier'));
        // console.log('price: ' + event.target.getAttribute('data-price'));
        // console.log('parent: ' + event.target.getAttribute('data-parent'));

        this.updateSummary();
        this.handleDependencies();

        this.ifIsActiveNextNode(event);

        // console.log(window.summary);
    }

    render() {

        let dependencies = this.checkDependencies()

        let title = this.props.selection.title;
        let description = this.props.selection.properties.description;
        let info = this.props.selection.properties.info;
        let infoImage = this.props.selection.properties.infoImage;

        let items = [];
        for (let j in this.props.selection.children) {
            let item = this.props.selection.children[j];
            // console.log(item);
            items.push(<SubSelection item={item} key={item.identifier} />);
        }

        let styles = this.props.isFirst ? null : {display: 'block', opacity: 0.3, pointerEvents: 'none'};

        return (
            <div className="main-selection my-5" data-dependencies={dependencies} style={styles} >
                <Title title={title} description={description} info={info} image={infoImage} />
                <div className="row" onChange={this.onChangeValue} >
                    {items}
                </div>
            </div>
        );
    }
}

export default MainSelection;
