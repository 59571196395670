import React from 'react';
import './App.scss';
import Summary from "./components/Summary";
import LeftFrame from "./components/LeftFrame";
import LogicFrame from "./components/LogicFrame";
import logo from "./LogoDark.svg"

function App() {

    const getPathSegment = () => {
        const uri = window.location.href;
        let uriPathSegment = uri.substring(uri.lastIndexOf('/') + 1);
        return uriPathSegment;
    };

    const getModel = () => {
        let pathSegment = getPathSegment();
        let models = window.metadata;
        for(let i in models) {
            if(models[i].properties.uriPathSegment === pathSegment) {
                return i;
            }
        }
        return false;
    }

    return (
        <div className="app">
            <header className="header">
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-4">
                            <a href="https://www.metallunddesign.at" className="btn-back-to-website">
                                <i className="fas fa-chevron-left" /> <span>Zurück zur Website</span>
                            </a>
                        </div>
                        <div className="col-4 text-center">
                            {/*<img src={logo} className="img-fluid brand" alt="Metall & Design" />*/}
                            <img src="/_Resources/Static/Packages/Inoovum.Site/mud-configurator/LogoDark.0ac08a02.svg" className="img-fluid brand" alt="Metall & Design" />
                        </div>
                        <div className="col-4 text-end">
                            {window.metadata.properties.priceCalculation &&
                                <Summary />
                            }
                        </div>
                    </div>
                </div>
            </header>
            <div id="percentage" className="percentage" />
            <main className="main">
                <div className="container-fluid px-0 h-100">
                    <div className="row h-100 g-0 align-items-center">
                        <div className="col-md-4 h-100 bg-left">
                            <div className="row align-items-center h-100">
                                <div className="col-12">
                                    <LeftFrame title={window.metadata.title} imageUri={window.metadata.imageUri} images={window.metadata.images} description={window.metadata.properties.description} link={window.metadata.properties.link} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 ms-auto">
                            <LogicFrame children={window.metadata.children} sideLabel={window.metadata.properties.sideLabel} noBevel={window.metadata.properties.noBevel} title={window.metadata.title}
                                        noAngleNoCorner={window.metadata.noAngleNoCorner}
                                        infotext1={window.metadata.infotext['info1'].infotext}
                                        infotext2={window.metadata.infotext['info2'].infotext}
                                        infotext3={window.metadata.infotext['info3'].infotext}
                                        infoimage1={window.metadata.infotext['info1'].infoimage}
                                        infoimage2={window.metadata.infotext['info2'].infoimage}
                                        infoimage3={window.metadata.infotext['info3'].infoimage}
                                        noDimensions={window.metadata.properties.noDimensions}
                                        dimensionsText={window.metadata.properties.dimensionsText}
                            />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
	// return (
	// 	<div className="app">
	// 		<header className="header">
	// 			<div className="container h-100">
	// 				<div className="row h-100 align-items-center">
	// 					<div className="col-4">
	// 						<a href="https://www.metallunddesign.at" className="btn-back-to-website">
	// 							<i className="fas fa-chevron-left" /> <span>Zurück zur Website</span>
	// 						</a>
	// 					</div>
	// 					<div className="col-4 text-center">
	// 						{/*<img src={logo} className="img-fluid brand" alt="Metall & Design" />*/}
	// 						<img src="/_Resources/Static/Packages/Inoovum.Site/mud-configurator/LogoDark.0ac08a02.svg" className="img-fluid brand" alt="Metall & Design" />
	// 					</div>
	// 					<div className="col-4 text-end">
	// 						{window.metadata[getModel()].properties.priceCalculation &&
	// 							<Summary />
	// 						}
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</header>
	// 		<div id="percentage" className="percentage" />
	// 		<main className="main">
	// 			<div className="container-fluid px-0 h-100">
	// 				<div className="row h-100 g-0 align-items-center">
	// 					<div className="col-md-4 h-100 bg-left">
	// 						<div className="row align-items-center h-100">
	// 							<div className="col-12">
	// 								<LeftFrame title={window.metadata[getModel()].title} imageUri={window.metadata[getModel()].imageUri} images={window.metadata[getModel()].images} description={window.metadata[getModel()].properties.description} link={window.metadata[getModel()].properties.link} />
	// 							</div>
	// 						</div>
	// 					</div>
	// 					<div className="col-md-8 ms-auto">
	// 						<LogicFrame children={window.metadata[getModel()].children} sideLabel={window.metadata[getModel()].properties.sideLabel} noBevel={window.metadata[getModel()].properties.noBevel} title={window.metadata[getModel()].title}
	// 									noAngleNoCorner={window.metadata[getModel()].noAngleNoCorner}
	// 									infotext1={window.metadata[getModel()].infotext['info1'].infotext}
	// 									infotext2={window.metadata[getModel()].infotext['info2'].infotext}
	// 									infotext3={window.metadata[getModel()].infotext['info3'].infotext}
	// 									infoimage1={window.metadata[getModel()].infotext['info1'].infoimage}
	// 									infoimage2={window.metadata[getModel()].infotext['info2'].infoimage}
	// 									infoimage3={window.metadata[getModel()].infotext['info3'].infoimage}
	// 									noDimensions={window.metadata[getModel()].properties.noDimensions}
	// 									dimensionsText={window.metadata[getModel()].properties.dimensionsText}
	// 						/>
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</main>
	// 	</div>
	// );
}

export default App;
