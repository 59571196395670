import React from "react";
import Gallery from "./Gallery/Gallery";

class LeftFrame extends React.Component {

    render() {
        return (
            <div id="leftFrame">
                <div className="row">
                    <div className="col col-frame ms-auto">
                        {this.props.imageUri &&
                            <div className="left-frame-image">
                                <img src={this.props.imageUri} alt={this.props.title} className={'img-fluid mb-4'}/>
                            </div>
                        }
                        <h1>{this.props.title}</h1>
                        {/*<h4>Modellbeschreibung</h4>*/}
                        <span className="d-block mb-5 main-description" dangerouslySetInnerHTML={{__html: this.props.description}} />
                        <div className="row">
                            <div className="col-md-6">
                                {this.props.images.length > 0 &&
                                    <Gallery images={this.props.images} />
                                }
                            </div>
                            <div className={this.props.images.length > 0 ? 'col-md-6' : 'col-12'}>
                                <a href="https://www.metallunddesign.at/gelaender" target="_blank" rel="noreferrer" className="btn btn-dark text-light w-100 mb-3" >
                                    <i className="fas fa-th-large me-2" /> Zur Übersicht
                                </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <a href={this.props.link} target="_blank" rel="noreferrer" className="btn btn-dark text-light w-100 mb-3" >
                                    <i className="fas fa-info-circle me-2" /> Zurück zum Modell
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LeftFrame;
