import React from "react";
import InfoButton from "../InfoButton";

class Title extends React.Component {

    render() {

        return (
            <div className="row">
                <div className="col-10">
                    <h4>{this.props.title}</h4>
                    <span className="step-description" dangerouslySetInnerHTML={{__html: this.props.description}} />
                </div>
                <div className="col-2 text-end">
                    <InfoButton title={this.props.info} image={this.props.image} />
                </div>
            </div>
        );
    }
}

export default Title;