import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const appIsready = (callback) => {
	if (document.readyState !== 'loading') callback();
	else document.addEventListener('DOMContentLoaded', callback);
}

// const addToSessionStore = function(item) {
//     console.log('add to session store');
//     console.log(item);
// }

const getPathSegment = () => {
	const uri = window.location.href;
	let uriPathSegment = uri.substring(uri.lastIndexOf('/') + 1);
	return uriPathSegment;
};

appIsready(() => {

	async function fetchConfiguratorData() {
		// const response = await fetch('http://dev.mud.dockyard.local/configurator/index.json');
		// const response = await fetch('/configurator/index.json');
		const response = await fetch(`/configurator/index/${getPathSegment()}`);
		const metadata = await response.json();
		window.metadata = metadata;
		window.summary = [];
		window.sides = parseInt(0);
		// console.log(metadata);
		const rootElement = document.getElementById('root');
		rootElement.classList.remove('root-preloader');
		ReactDOM.render(
			<React.StrictMode>
				<App />
			</React.StrictMode>,
			rootElement
		);
	}
	fetchConfiguratorData().then(r => console.log('data loaded.'));
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
