import React from "react";
import Side from "./Side";
import MeasureInput from "./MeasureInput";
import Corners from "./Corners";
import Title from "../Selection/Title";

class Sides extends React.Component {

    constructor(props) {
        super(props);
        this.addSides = this.addSides.bind(this);
        this.state = { items: []};
        this.state = { inputs: []};
        this.state = { corners: []};
        this.state = {
            active: false,
            sides: 0
        };
        this.onChangeValue = this.onChangeValue.bind(this);
    }

    renderSides(int) {
        let sides = []
        for (let i = 0; i < parseInt(int); i++) {
            this.state.sides = this.state.sides + 1;
            sides.push(<Side key={'side_' + this.state.sides} id={'count_' + this.state.sides} number={this.state.sides} sideLabel={this.props.sideLabel} />);
        }
        return sides;
    }

    addSides(int) {
        let beforeItems = [];
        if(typeof this.state.items !== 'undefined') {
            beforeItems = this.state.items
        }
        let newItems = this.renderSides(int);
        this.setState(state => ({
            items: beforeItems.concat(newItems)
        }));
    }

    renderInputs(int) {
        let inputs = []
        for (let i = 1; i < parseInt(int) + 1; i++) {
            inputs.push(<MeasureInput key={'input_' + i} id={'input_' + i} number={i} noBevel={this.props.noBevel} />);
        }
        return inputs;
    }

    renderCorners(int) {
        let corners = []
        if(parseInt(int) > 0) {
            corners.push(<div key="cornerHeader" className="mb-3"><Title title="Ecken"
                                description="Geben Sie an, ob es sich bei den Ecken um innere oder äußere Ecken handelt wenn diese nicht 90° betragen"
                                info={this.props.infotext3} image={this.props.infoimage3} /></div>);
        }
        for (let i = 1; i < parseInt(int) + 1; i++) {
            if(parseInt(int) > 0) {
                corners.push(<Corners key={'input_' + i} id={'input_' + i} number={i} />);
            }
        }
        return corners;
    }

    addInputs(int) {
        this.setState(state => ({
            inputs: this.renderInputs(int)
        }));
    }

    addCorners(int) {
        this.setState(state => ({
            corners: this.renderCorners(int)
        }));
    }

    onChangeValue(event) {
        // console.log(event.target.value);
        // console.log('count: ' + event.target.getAttribute('data-count'));
        let dataCount = event.target.getAttribute('data-count');

        document.querySelectorAll('.form-check-button-outline').forEach(formCheckLabel => {
            if(parseInt(formCheckLabel.getAttribute('data-count')) <= parseInt(dataCount)) {
                formCheckLabel.classList.add('active');
            } else {
                formCheckLabel.classList.remove('active');
            }
        });

        this.addInputs(parseInt(dataCount));
        this.addCorners((parseInt(dataCount) - 1));
    }

    componentDidMount() {
        window.addEventListener('load', this.addSides(4));
    }

    render() {

        const slidesToAdd = 4;

        const noAngleNoCorner = this.props.noAngleNoCorner === true ? 'true' : 'false';

        return (
            <div>
                <div id="sides" className="row mt-3" onChange={this.onChangeValue} >
                    {this.state.items}
                </div>
                <button id="addNewSides" type="button" className="btn btn-outline-primary btn-sm text-dark mt-2" onClick={() => this.addSides(slidesToAdd)} >Weitere Seiten (+{slidesToAdd})</button>
                <div className="mt-4">
                    {this.state.inputs &&
                        <Title title="Maße" description="Maße auf entsprechender Seite angeben (in Millimeter)"
                               info={this.props.info} image={this.props.image} />
                    }
                    <div id="measure" className="row mt-3">
                        {this.state.inputs}
                    </div>
                </div>

                {noAngleNoCorner !== 'true' &&
                    <div className="mt-4">
                        <div id="corners" className="mt-3">
                            {this.state.corners}
                        </div>
                    </div>
                }

            </div>
        );
    }
}

export default Sides;
