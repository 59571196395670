import React, {Fragment} from "react";

class SubSelection extends React.Component {

    // constructor(props) {
    //     super(props);
    //     this.addToSummary = this.addToSummary.bind(this);
    //
    //     this.toggleClass= this.toggleClass.bind(this);
    //     this.handleDependencies = this.handleDependencies.bind(this);
    //     this.state = {
    //         active: false,
    //     };
    // }

    // toggleClass() {
    //     const currentState = this.state.active;
    //     this.setState({ active: !currentState });
    //     return !currentState;
    // };
    //
    // removeFrom(arr, id) {
    //     for (let summaryItem in arr) {
    //         if(arr[summaryItem].identifier === id) {
    //             arr.splice(summaryItem, 1);
    //         }
    //     }
    // }

    // getMainSelection() {
    //     let mainSelections = [];
    //     document.querySelectorAll('.main-selection').forEach(mainSelection => {
    //         mainSelections.push(mainSelection);
    //     });
    //     return mainSelections;
    // }
    //
    // handleDependencies() {
    //     let mainSelections = this.getMainSelection();
    //     for(let i in mainSelections) {
    //         mainSelections[i].style.display = 'block';
    //         if(typeof mainSelections[i].getAttribute('data-dependencies') !== 'undefined' && mainSelections[i].getAttribute('data-dependencies') !== 'false') {
    //             mainSelections[i].style.display = 'none';
    //             let dataDependencies = mainSelections[i].getAttribute('data-dependencies');
    //             for (let j in window.summary) {
    //                 let identifier = window.summary[j].identifier;
    //                 console.log('summary identifier: ' + identifier);
    //                 if(dataDependencies.includes(identifier)) {
    //                     mainSelections[i].style.display = 'block';
    //                 }
    //             }
    //         }
    //     }
    // };

    // addToSummary(id, price) {
    //
    //     console.log('ischanged');
    //
    //     let state = this.toggleClass();
    //     // let state = this.state;
    //
    //     if(state) {
    //         window.summary.push({'identifier': id, 'price': price});
    //     } else {
    //         this.removeFrom(window.summary, id);
    //     }
    //
    //     console.log(state);
    //     console.log('id: ' + id);
    //     console.log(window.summary);
    //
    //     window.buildSummary();
    //
    //     this.handleDependencies();
    //
    // }

    checkDependencies() {
        let dependencies = this.props.item.properties.dependencies;
        let dependenciesData = false;
        if(typeof dependencies !== 'undefined') {
            dependenciesData = dependencies.join(',');
        }
        return dependenciesData;
    };

    render() {
        let dependencies = this.checkDependencies()

        return (
            <div className="col-md-4 col-6 mb-4 sub-selection" data-dependencies={dependencies ? dependencies : 'false'} >
                <div id={'label_' + this.props.item.identifier} className="form-check form-check-button" >
                    <input className="form-check-input" type="radio" name={'option[items][' + this.props.item.parent + ']'} id={'check_' + this.props.item.parent + this.props.item.identifier} data-identifier={this.props.item.identifier} data-parent={this.props.item.parent} data-price={this.props.item.price} value={this.props.item.identifier} />
                    <label className="form-check-label" htmlFor={'check_' + this.props.item.parent + this.props.item.identifier} >
                        {this.props.item.imageUri &&
                            <img src={this.props.item.imageUri} alt={this.props.item.title}
                                 className={'img-fluid mb-2 d-block'}/>
                        }
                        <div className="d-block">
                            <span className="d-block title">{this.props.item.title}</span>
                            {this.props.item.properties.description &&
                                <span className="d-block description" dangerouslySetInnerHTML={{__html: this.props.item.properties.description}} />
                            }
							{this.props.item.main && this.props.item.main.length > 0 &&
								<Fragment>
									{this.props.item.main.map(item =>
										<div className="d-none text-start customInputWrapper">
											<label htmlFor={`input_${item.identifier}`} className=" mt-4 mb-1">
												{item.properties.description.replace(/<\/?[^>]+(>|$)/g, "")}
											</label>
											<input id={`input_${item.identifier}`} name={'option[items][' + this.props.item.parent + '][' + this.props.item.identifier + '][value]'} type="input" className="form-control" placeholder={item.properties.suffix.replace(/<\/?[^>]+(>|$)/g, "")} />
										</div>
									)}
								</Fragment>
							}
                        </div>
                    </label>
                </div>
                {/*<button type="button" className={'btn btn-primary btn-sub-selection' + (this.state.active ? ' btn-danger' : '')} data-identifier={this.props.item.identifier} data-parent={this.props.item.parent} data-price={this.props.item.price} onClick={() => this.addToSummary(this.props.item.identifier, this.props.item.price)} >*/}
                {/*    <img src={this.props.item.imageUri} alt={this.props.item.title} className={'img-fluid mb-2 d-block'} />*/}
                {/*    <span className="d-block title">{this.props.item.title}</span>*/}
                {/*    {this.props.item.properties.description &&*/}
                {/*        <span className="d-block description">{this.props.item.properties.description}</span>*/}
                {/*    }*/}
                {/*</button>*/}
            </div>
        );
    }
}

export default SubSelection;
