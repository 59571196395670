import React from "react";
import CornerValues from "./CornerValues";

class Corners extends React.Component {

    constructor(props) {
        super(props);
        this.state = { values: []};
        this.state = {
            active: false
        };
        this.onChangeValue = this.onChangeValue.bind(this);
    }

    renderValues(id, label) {
        let values = (<CornerValues key={'input_' + id} id={'input_' + id} label={label} number={id} />);
        return values;
    }

    addValues(id, label) {
        this.setState(state => ({
            values: this.renderValues(id, label)
        }));
    }

    removeValues() {
        this.setState(state => ({
            values: []
        }));
    }

    onChangeValue(event) {
        document.querySelectorAll('.btn-corner' + event.target.getAttribute('data-parent')).forEach(btnCorner => {
            btnCorner.parentNode.classList.remove('active');
        });
        event.target.parentNode.classList.add('active');

        if(event.target.hasAttribute('data-isnt90')) {

            let label = false;
            if(event.target.hasAttribute('data-label')) {
                let nextSide = parseInt(event.target.getAttribute('data-label')) + 1;
                label = event.target.getAttribute('data-label') + '-' + nextSide;
            }

            this.addValues(event.target.getAttribute('data-number'), label);
        } else {
            this.removeValues();
        }
    }

    render() {

        let nextSide = parseInt(this.props.number) + 1;
        let label = this.props.number + '-' + nextSide;

        return (
            <div>
                <p>Seite {label}:</p>
                <div className="row align-items-center mb-3" onChange={this.onChangeValue} >

                    <div className="col-4">
                        <div id={'corners_' + this.props.number} className="form-check form-check-button-outline" >
                            <input className={'form-check-input btn-corner' + this.props.id} type="radio" name={'option[corners][' + this.props.number + '][]'} id={'option_corner_1_' + this.props.id} data-parent={this.props.id} data-number={this.props.number} value="Außenecke" />
                            <label className="form-check-label" htmlFor={'option_corner_1_' + this.props.id} >
                                Außenecke
                            </label>
                        </div>
                    </div>

                    <div className="col-4">
                        <div id={'corners_' + this.props.number} className="form-check form-check-button-outline" >
                            <input className={'form-check-input btn-corner' + this.props.id} type="radio" name={'option[corners][' + this.props.number + '][]'} id={'option_corner_2_' + this.props.id} data-parent={this.props.id} data-number={this.props.number} value="Innenecke" />
                            <label className="form-check-label" htmlFor={'option_corner_2_' + this.props.id} >
                                Innenecke
                            </label>
                        </div>
                    </div>

                    <div className="col-4">
                        <div id={'corners_' + this.props.number} className="form-check form-check-button-outline" >
                            <input className={'form-check-input btn-corner' + this.props.id} type="radio" name={'option[corners][' + this.props.number + '][]'} id={'option_corner_3_' + this.props.id} data-isnt90={true} data-parent={this.props.id} data-label={this.props.number} data-number={this.props.number} value="Nicht 90°" />
                            <label className="form-check-label" htmlFor={'option_corner_3_' + this.props.id} >
                                Nicht 90°
                            </label>
                        </div>
                    </div>

                </div>

                <div className="col-12">
                    {this.state.values}
                </div>
            </div>

        );
    }
}

export default Corners;