import React from "react";

class CornerValues extends React.Component {

    render() {

        return (
            <div className="row mt-2 mb-4">
                <div className="col-12">
                    <div className="row align-items-center mt-2">

                        <div className="col-md-6">
                            <div className="row align-items-center mt-2">
                                <div className="col-md-5">Seite {this.props.label}, Winkel (°):</div>
                                <div className="col-md-7">
                                    <input className="form-control" name={'option[corners][' + this.props.number + '][angle]'} placeholder="Wert" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="row align-items-center mt-2">
                                {/*<div className="col-md-7">oder Grundabmessungen (mm):</div>*/}
                                <div className="col-md-5">oder Diagonale (mm):</div>
                                <div className="col-md-7">
                                    <input className="form-control" name={'option[corners][' + this.props.number + '][basicdimension]'} placeholder="Wert" />
                                </div>
                            </div>
                        </div>

                        {/*<div className="col-5">Seite {this.props.label}, Winkel (°):</div>*/}
                        {/*<div className="col-3">*/}
                        {/*    <input className="form-control" name="option[corners][input_1][angle]" required="number" placeholder="Wert" />*/}
                        {/*</div>*/}
                    </div>
                    {/*<div className="row align-items-center mt-2">*/}
                    {/*    <div className="col-5">oder Grundabmessungen (mm):</div>*/}
                    {/*    <div className="col-3">*/}
                    {/*        <input className="form-control" name="option[corners][input_1][basicdimension]" required="number" placeholder="Wert" />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}

export default CornerValues;