import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';

function Gallery(images) {
    const [toggler, setToggler] = useState(false);

    let galleryImages = [];
    for(let i in images.images) {
        galleryImages.push(images.images[i].image);
    }

    return (
        <>
            <button type="button" className="btn btn-dark text-light w-100 mb-3" onClick={() => setToggler(!toggler)} >
                <i className="fas fa-photo-video me-2" /> Weitere Fotos
            </button>
            <FsLightbox
                toggler={toggler}
                sources={galleryImages}
            />
        </>
    );
}

export default Gallery;