import React from "react";

class InfoButton extends React.Component {

    constructor(props) {
        super(props);
        this.toggleBox= this.toggleBox.bind(this);
        this.state = {
            active: false,
        };
    }

    toggleBox() {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
        return !currentState;
    };

    render() {
        return (
            <div className="info-box">
                {this.props.title &&
                    <div>
                        <button type="button" className="btn btn-link text-secondary btn-help" data-bs-toggle="tooltip"
                                data-bs-placement="top" title={this.props.title} onClick={() => this.toggleBox()} >
                            <i className={this.state.active ? 'far fa-times-circle' : 'far fa-question-circle'} />
                        </button>
                        <div className={'info-box-box' + (this.state.active ? ' d-block' : ' d-none')} >
                            <div className="box-wrapper">
                                <span dangerouslySetInnerHTML={{__html: this.props.title}} />
                                {this.props.image &&
                                    <div className="info-box-image mt-3">
                                        <img src={this.props.image} className="img-fluid" alt={this.props.title}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default InfoButton;