import React from "react";

class Side extends React.Component {

    render() {

        return (
            <div className="col-md-3 col-6 mb-4">
                {/*<button type="button" className="btn btn-outline-primary text-dark w-100 mb-4 side">*/}
                {/*    {this.props.number}*/}
                {/*</button>*/}
                <div id={'sides_' + this.props.number} className="form-check form-check-button-outline" data-count={this.props.number} >
                    <input className="form-check-input" type="radio" name={'option[sides][]'} id={'option_side' + this.props.id} data-count={this.props.number} value={this.props.number} />
                    <label className="form-check-label" htmlFor={'option_side' + this.props.id} >
                        {this.props.number}. {this.props.sideLabel ? this.props.sideLabel : 'Seite'}
                    </label>
                </div>
            </div>
        );
    }
}

export default Side;