import React from "react";

class InputForm extends React.Component {

    render() {
        let type = this.props.type ? this.props.type : 'input';

        return (
            <div id={'inputForm' + this.props.id} >
                <div className="mb-3">
                    <label htmlFor={this.props.id} className="form-label">{this.props.label}</label>
                    {this.props.textarea &&
                        <textarea id={this.props.id} name={this.props.name} className={'form-control'} placeholder={this.props.placeholder ? this.props.placeholder : this.props.label} required={this.props.required ? this.props.required : false} />
                    }
                    {!this.props.textarea &&
                        <input id={this.props.id} name={this.props.name} type={type} className={'form-control'} placeholder={this.props.placeholder ? this.props.placeholder : this.props.label} required={this.props.required ? this.props.required : false} />

                    }
                </div>
            </div>
        );
    }
}

export default InputForm;