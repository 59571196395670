import React from "react";

class UploadForm extends React.Component {

    render() {
        return (
            <div id={'uploadForm' + this.props.id} >
                <h4>{this.props.title}</h4>
                <div className="mb-3">
                    <label htmlFor={this.props.id} className="form-label">{this.props.label}</label>
                    <input name={this.props.name} id={this.props.id} type={'file'} className={'form-control'} multiple={this.props.multiple} />
                </div>
            </div>
        );
    }
}

export default UploadForm;