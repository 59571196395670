import React from "react";
import Title from "../Selection/Title";
import Sides from "./Sides";

class Measurement extends React.Component {

    render() {

        return (
            <>
                {!this.props.noDimensions &&
                    <div id="measurement">
                        <Title title="Abmessungen"
                               description={this.props.dimensionsText ? this.props.dimensionsText : 'Wie viele Seiten sollte Ihr Geländer haben?'}
                               info={this.props.infotext1} image={this.props.infoimage1}/>
                        <Sides sideLabel={this.props.sideLabel} noBevel={this.props.noBevel}
                               info={this.props.infotext2} image={this.props.infoimage2}
                               infotext3={this.props.infotext3} infoimage3={this.props.infoimage3}
                               noAngleNoCorner={this.props.noAngleNoCorner}/>
                    </div>
                }
            </>
        );
    }
}

export default Measurement;
