import React from "react";
import MainSelection from "./Selection/MainSelection";
import UploadForm from "./Forms/UploadForm";
import InputForm from "./Forms/InputForm";
import Measurement from "./Measurement/Measurement";

class LogicFrame extends React.Component {

    render() {

        let selections = [];
        let isFirst = true;
        for (let i in this.props.children) {
            let selection = this.props.children[i];
            // console.log(selection);
            selections.push(<MainSelection selection={selection} key={selection.identifier} isFirst={isFirst} />);
            isFirst = false;
        }

        return (
            <div id="logicFrame">
                <div className="row">
                    <div className="col col-frame me-auto">
                        <input type="hidden" name="model" value={this.props.title} />
                        <Measurement sideLabel={this.props.sideLabel} noBevel={this.props.noBevel} infotext1={this.props.infotext1} infoimage1={this.props.infoimage1} infotext2={this.props.infotext2} infoimage2={this.props.infoimage2} infotext3={this.props.infotext3} infoimage3={this.props.infoimage3} noAngleNoCorner={this.props.noAngleNoCorner} noDimensions={this.props.noDimensions} dimensionsText={this.props.dimensionsText} />
                        {selections}
                        <UploadForm id="groundPlan" title="Individuellen Grundriss hochladen" label={'Bitte Grundriss "Sonderform" hochladen'} name="groundPlan" />
                        <UploadForm id="images" title="Baustellenfotos" label={'Fotos von Ihrer Baustelle hochladen'} multiple={true} name="images" />

                        <div className="d-block mt-5 mb-1 pt-3">

                            <h4>Ihre Daten</h4>

                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <InputForm id="firstname" label="Vorname" required={true} name="firstname" />
                                </div>
                                <div className="col-md-6">
                                    <InputForm id="lastname" label="Nachname" required={true} name="lastname" />
                                </div>
                            </div>

                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <InputForm id="address" label="Straße und Hausnummer" required={true} name="address" />
                                </div>
                                <div className="col-md-2">
                                    <InputForm id="zip" label="Postleitzahl" type="number" required={true} name="zip" />
                                </div>
                                <div className="col-md-4">
                                    <InputForm id="city" label="Ort" required={true} name="city" />
                                </div>
                            </div>

                            <div className="row align-items-center mb-3">
                                <div className="col-md-6">
                                    <InputForm id="phone" label="Telefonnummer" required={true} placeholder="Bitte geben Sie Ihre Rückruf-Telefonnummer an." name="phone" />
                                </div>
                                <div className="col-md-6">
                                    <InputForm id="email" label="E-Mail Adresse" type="email" required={true} placeholder="Bitte geben Sie Ihre E-Mail Adresse an." name="email" />
                                </div>
                            </div>

                            <h4>Vertriebspartner</h4>

                            <InputForm id="distributor" label="Vertriebspartner" placeholder="Bitte geben Sie den Vertriebspartner an." name="distributor" />

                            <InputForm id="distributorZip" label="Postleitzahl Vetriebspartner" placeholder="Bitte geben Sie die Postleitzahl des Vertriebspartners an." name="distributorZip" />

                            <InputForm id="distributorContact" label="Kontakt Vetriebspartner" placeholder="Bitte geben Sie den Kontakt beim Vertriebspartners an." name="distributorContact" />

                            <div className="mt-4">
                                <InputForm id="message" textarea={true} label="Ihre Nachricht an uns" placeholder="Ihre zusätzlichen Informationen." name="message" />
                            </div>

                        </div>

						<div style={{display: 'block', marginBottom: '5rem'}}>
							<button type="submit" className="btn btn-secondary text-light btn-lg w-100">
								<i className="far fa-paper-plane me-2" /> <strong>Anfrage senden</strong>
							</button>
						</div>


                    </div>
                </div>
            </div>
        );
    }
}

export default LogicFrame;
