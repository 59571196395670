import React from "react";
import ReactDOM from "react-dom";
import NumberFormat from 'react-number-format';

class Summary extends React.Component {

    render() {
        return (
            <div id="summary"><i className="fas fa-shopping-cart" /></div>
        );
    }
}

class SummaryContent extends React.Component {

    render() {
        return (
            <div className="summary-content">
                <i className="fas fa-shopping-cart me-2" /> Artikel: {this.props.count} / <NumberFormat value={this.props.fullprice} decimalScale={2} fixedDecimalScale={true} decimalSeparator={','} thousandSeparator={'.'} prefix={'€ '} displayType={'text'} />
            </div>
        );
    }
}

window.buildSummary = function() {

    const summary = window.summary;
    let price = parseFloat('0');
    let summaryLength = parseInt(0);
    for (let summaryItem in summary) {
        if(summary[summaryItem].price) {
            summaryLength = summaryLength + parseInt(1);
            price = price + parseFloat(summary[summaryItem].price);
        }
    }

    const getVisibleMainSelections = () => {
        let mainSelections = [];
        document.querySelectorAll('.main-selection').forEach(mainSelection => {
            if(mainSelection.style.display !== 'none') {
                mainSelections.push(mainSelection);
            }
        });
        return mainSelections.length;
    }

    const checkPercentage = (summaryLength) => {
        let childrenCount = getVisibleMainSelections();
        let percentage = ((100 / parseInt(childrenCount)) * parseInt(summaryLength));
        document.getElementById('percentage').style.width = (percentage + '%');
    }

    document.addEventListener('mousedown', checkPercentage(summaryLength));

    // console.log('summary length: ' + summaryLength);

    if(document.getElementById('summary')) {
        ReactDOM.render(
            <SummaryContent count={summaryLength} fullprice={price} />, document.getElementById('summary')
        );
    }
}

export default Summary;
